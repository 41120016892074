export class Endereco {
    public cep:string=""; 
    public endereco:string = "";
    public numero:string="";
    public bairro:string="";
    public cidade:string="";
    public pais:string="";
    public estado:string="";
    public complemento:string=""; 
    public referencia:string='';
}